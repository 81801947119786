export const data = [
  "te quiero",
  "muela rota",
  "jueves santo",
  "tercera cita #freeanimal",
  "jubilación cubano",
  "Cavalli y Rosé",
  "La Fábrica",
  "La Deriva",
  "aparcamiento Copo",
  "Chiringuito LGTBQIr...",
  "me encantas",
  "que bien",
  "cena Enmienda",
  "weChat",
  "ataque de besos",
  "China...",
  "puño, puño, corazón",
  "aeropuerto",
  "modo zahire",
  "septiembre",
  "te adoro",
  "las negras",
  "Lisboa",
  "Parmigiana",
  "hablar, gablar, hablar",
  "contigo todo es mejor",
  "Boda Juande",
  "Barbate",
  "nuestros besos no son normales",
  "25 abril",
  "Carola",
  "Sevilla",
  "29740",
  "29700",
  "Chocolate",
  "palomitas",
  "Acebuchal",
  "Chimenea",
  "Nuestra primera foto",
  "Me pones perraca",
  "El príncipe de un sueño del que no quiero despertar",
  "Astronautas",
  "Cadi!",
  "Tu mano en mi cuello",
  "Niña Pastori",
  "Bollullos",
  "Juntos",
  "Cama de 80",
  "Ahí hay cuatro patas",
  "Teguiseee",
  "Gran hotel",
  "Lanzarote",
  "Un pendiente",
  "Mensajes al despertar",
  "te amo",
  "Jaime Mario",
  "Videollamadas",
  "despertador para verte :)",
  "Margaritas blancas",
  "Margaritas tequila",
  "En moto, mi mano en tu pierna",
  "Atardecer negras",
  "Tirita en el tobillo",
  "tu nariz <3",
  "Abrazarte",
  "culete",
  "Enfrente del Niza",
  "Vaya...",
  "Mi cepillo, tu cepillo",
  "fiestas pijama",
  "Chiki Beach",
  "Salitre",
  "Playita",
  "Tarifa",
  "Zaboreee",
  "Hummus (con boca cerrada)",
  "Reciclo",
  "Nuestra casa de caleta",
  "Casita minimal",
  "6 niños",
  "San José",
  "Zaragoza",
  "No sabes bien lo bien que sabes",
  "Recogerte en la clínica",
  "Algarrobo",
  "1925 fresquita",
  "Ezozedio",
  "Javi Cantero",
  "Simple",
  "Larios",
  "Adolescentes en el Marqués",
  "tus carcajadas",
  "nuestras siestas",
  "septiembre",
  "carita del revés",
  "nuestros viajes",
  "siempre",
  "todo lo que nos queda...",
  "me vuelves loco",
  "estoy loquillo",
  "foto Shushi Milán",
  "bucear",
  "hotel en Chile :)",
  "dormir juntos",
  "en el escalón de cada noche...",
  "Beleeeeen",
  "Zahara",
  "nunca he sentido esto",
  "mi mujé",
  "en los que mi corazón pienso que se va a parar",
  "un beso largo, muy largo",
  "#hastags",
  "tu famili <3",
  "Cirque du Soleil",
  "Capusho",
  "#ñiquiñiqui",
  "Voy a ser muy prudente a la hora de escribir",
  "alargaaaar palabraaaas",
  "B y J",
  "creo que ya no puedo quererte más",
  "que sensación más bonita, estar despistado y acordarme de ti",
  "con el dedo: te quiero, en la espalda",
  "mi cara de emoción",
  "soy tuyo",
  "pienso en ti",
  "eres mi alegría",
  "mi pelo corto",
  "26 Abril",
  "celos, siento celos...",
  "mi compañera de viaje",
  "sorprenderte <3",
  "durmiendo en tu ombligo",
  "vida",
  "amor",
  "Chavico",
  "vestido de lunares",
  "Muchísimo",
  "Te quiero a reventar",
  "No te puedo querer más",
  "me encantas",
  "estás guapísima",
  "mi niña",
  "Proximamente Jeréz",
  "Proximamente Córdoba",
  "Te quiero. Tanto como cada vez que te lo digo. Nunca es menos",
  "tus audios <3",
  "recogerte",
  "ese pellizco",
  "No me voy a cansar de decirtelo",
  "para siempre",
  "te quiero con locura",
  "cosquillas en la cara",
  " pienso en esta dos boches juntos, y en la salida de sábado con tus amigos. En lo guapísima que ibas, que no te haces una idea. En nuestro diita en fuengirola, en verte jugando con Pepa, en nuestra fiesta del pijama (esta vez multitudinaria)",
  "El caso es que nunca he querido tanto. Y me encanta esta sensación.",
  "los dos",
  "tu pierna encima mía",
  "mirarte antes de dormir",
  "ojalá",
  "tanto...",
  "Darte los buenos días",
  "Que no solo me va a hacer feliz hoy. Ni mañana. Si no q he encontrado a la mujer de mi vida",
  "Llevo 12 meses viviendo en una nube",
  "365 días... y los que quedan",
  "nos vamos a entender",
  "cuidarte",
  "Es imposible quererte más",
  "tu sonrisa",
  "Que bonito todo contigo",
  "ya no quiero dormir sin tí",
  "Amorcete",
  "Te quiero, te quiero, te quiero, te quiero, te quiero, te quiero, te quiero, te quiero, te quiero, te quiero...",
  "con todo mi corazón",
  "haces que contigo quiera todo",
  "balanabana... :)",
  "mi vida contigo",
  "tenlo siempre presente",
  "Me apetecía recordarte una vez más lo que me haces sentir y cómo",
  "nunca he querido tanto a nadie",
  "nuestros findes",
  "abrazarte durante una hora",
  "mi brazo bajo tu cuello",
  "hasta decir basta",
  "eres increíble",
  "hacer las maletas",
  "nuestro baile en el coche, Lanzarote",
  "te echo de menos, siempre",
  "dormir sin despertador",
  "eres mi alegría",
  "que vuelvas del gym (:",
  "muchísimo",
  "me olvido de tu agenda, pero no de lo que te quiero",
  "no te imaginas lo que eres para mí",
  "con lo difícil que es encontrar a esa persona, y apareciste tú",
  "mi persona favorita",
  "Qué bien que en mis pupilas siga entrando luz del sol",
  "Qué bien que te pusiste en medio",
  "Que no te has ido y que te tengo cerca",
  "No sería lo mismo imaginarte",
  "Qué maravillosamente oportuno",
  "soñar contigo",
  "mi sonrisa de oreja a oreja",
  "un beso como un castillo",
  "voy a ayudarte siempre",
  "comerte a besos",
  "Estoy cenando contigo. Hablando de todo un poco. Viéndote... Eres la mujer de mi vida",
  "gracias por todo",
  "pedirte perdón por respirar fuerte",
  "<3",
  "soy tuyo",
  "mi cabeza pensando en tí",
  "Tú <3",
  "andar de la mano",
  "ponerme nerviosete (:",
  "saber que te voyna ver",
  "vivir juntos",
  "mi Fali XD",
  "En mi pecho, tú",
  "cantarte, mal, pero cantarte",
  "tu vestido amarillo",
  "Ya eres parte de mi vida. No hay marcha atrás. Solo para delante 🙂",
  "❤️",
  "Espero que estés descansando amor mío, y que mañana te despiertes hecha una rosa",
  "hola bombón",
  "🤜🤛🏾❤️",
  "Halley Berlin",
  "Que cosquilleo me sigue entrando cada vez que te leo...",
  "Eres...",
  "Te quiero todos los días, en todos los momentos",
  "decirte TE AMO por primera vez...",
  "tengo ganas de tí",
  "Lunes por la mañana, lunes por l tarde, lunes por la noche, martes por la mañana, martes por la tarde, martes por la noche. El miércoles por la mañana, el miércoles por la tarde, el miércoles por la noche... el jueves por la mañana, el jueves por la tarde, el jueves por la noche también. El viernes por la mañana, el viernes por la tarde, y el viernes por la noche. El sábado completo, por la mañana, por la tarde y por la noche. Y el domingo, el domingo te echo de menos por la mañana, te echo de menos por la tarde, y por la noche...",
  "😘😘😘😘😘😘😘😘😘😘😘😘😘😘😘😘😘😘😘😘😘😘😘😘😘😘😘😘😘😘😘😘😘😘😘😘",
  "a rabiar",
  "Que bonito todo",
  "me iría contigo a cualquier parte",
  "me alegras los días",
  "una ducha juntos",
  "Sé que te cuido, y que miro por ti, y que tú eres consciente de eso. Pero Esq quiero que siempre lo tengas claro.",
  "Vaya cosa más bonita vivir así",
  "Me quedo contigo",
  "en la mejilla, en la nariz, en la comisura de tus labios, en la barbilla, en el cuello...",
  "vente conmigo, al fin del mundo",
  "pufff",
  "🙃",
  "Tell me somethin', girl. Are you happy in this modern world?",
  "Llevamos 9 meses y una semana y pico (siempre me ha sido difícil acordarme de estas cosas y contigo siempre lo tengo presente)",
  "tienes que saber que está siendo una de las mejores épocas que recuerdo.",
  "Si lo tuviera que explicar de la manera más fácil posible diría que soy feliz.",
  "Que tengo una ilusión tremenda. Y que a la vez estoy tranquilo (No siempre pero casi siempre) 😂",
  "Has llegado y lo has puesto todo patas por hombro",
  "pero es una sensación tan bonita que solo quiero que le sigas dando vueltas a mi vida.",
  "Desde la primera noche que estuvimos juntos, no ha habido ni un solo momento en el que te haya echado de más.",
  "De echo solo hago echarte de menos.",
  "Me estoy sorprendiendo siendo tan paciente, xq desde el primer momento pienso en vivir a tu lado.",
  "En tenerte siempre. Y en no dejar de vivir todo contigo.",
  "Pero tú me trasmites calma. Haces que lo quiera ya, pero que no sea difícil esperar.",
  "Si no las ganas de decirte esto.",
  "Espero seguir aprendiendo lo que te hace feliz, lo que no",
  "aprender lo que te crea inseguridad, y lo que hace que estés así 😍.",
  "Y seguir haciéndolo cada día.",
  "Ahora entiendo que es eso de tener ganas de cuidar a alguien. De mirar por esa persona.",
  "Y de desear que nunca se acabe este sentimiento.",
  "(No quiero ponerme como el del sueño del que nunca quise despertar, ni como los mensajes profundos de instagram).",
  "Posiblemente si escribiera con calma y pensándolo más lo expresaría de otra manera",
  "pero en el fondo diría lo mismo que estoy diciendo.",
  "La familia no se elige y los amigos llegan, no es algo que se pueda controlar.",
  "En cambio tú eres lo mejor que me ha pasado en mi vida.",
  "Y te lo digo con 33 años y completamente seguro.",
  "No te enfades conmigo nunca, porfi. Sé que es imposible pero inténtalo. Yo intentaré no dar ningún motivo",
  "Siempre te lo digo, Pero te quiero más de lo que tú crees",
  "Es todo Bueno vida. Y todo amor. Amor del Bueno",
  "todo lo que empiece por: nuestro",
  "Capilla del Acebuchal",
  "me vas a tener siempre",
  "el puente rojo de Lisboa :)",
  "de cabeza a pies",
  "Con el tesoro que encontré hace 8 meses y 26 días",
  "Me apetecía recordarte una vez más lo que me haces sentir y cómo.",
  "Te prometo que pienso en ti, en nosotros, y esque no puedo ser más feliz...",
  "Cuando te iluminas, o cuando ríes a carcajadas.. Imagino que eso que me entra es el amor.",
  "Me vuelves loco vida mía, me haces tremendamente feliz, y no tengo intención de dejar de hacer ninguna de las dos cosas.",
  "Llevo mucho sin mandarte un mensajillo, de esos que ves cuando te despiertas.",
  "Solo quería decirte, si na vez más, que la idea de estar junto a ti hace que sea feliz.",
  "Que la cosa tan bonita que siento cuando te abrazo, no se puede explicar.",
  "Y que por mucho que pase el tiempo, y que estemos más acostumbrados a estar el uno con el otro, no me voy a cansar de decirte que estoy enamorado de ti. Que eres lo más bonito que me ha pasado.",
  "Gracias por aparecer",
  "Pensándolo bien.. te quiero más q ayer",
  "Y eso es x ti amor",
  "Impresionante, aunque te pique el mosquito",
  "Querida Belén Alcoba:",
  "Quizás yo soy más de ir diciendo algunas cositas poquito a poco, a mandar un mensaje largo en estos días que son más especiales",
  "Pero es nuestro primer fin de año, y nunca está de más volver a recordarte esto tan bonito que siento.",
  "Me has cambiado la vida, que yo ya pensé que era perfecta.",
  "Y ahora me encuentro con, que además de perfecta, tengo a mi lado a la mejor persona que podía haber imaginado.",
  "Y que además, la quiero como no sabía que se podía querer.",
  "Te lo digo siempre, pero no puedo explicarte lo que siento por ti.",
  "Eres el amor de mi vida.",
  "Que feliz vamos a ser en el 2019.",
  "Eres lo mejor",
  "Dile q tiene razón, que te quiero tanto tanto.. que ni yo lo sé calcular",
  "Hoy te achucho",
  "Jengibre",
  "nuestros bimis",
  "conocer a tus amigos",
  "en loq ue dura un cigarro me dá tiempo a imaginar como serán nuestras vidas",
  "te imagino de mi mano, sentaita frente al mar, se me escapa una sonrisa",
  "la última en quien pienso cada noche, por quien me despierto cada día",
  "Mañana hablaremos abrazaditos y dándonos besetes entre frase y frase.",
  "Aaayyy amor...",
  "pero hasta que has aparecido tu y me apetece estar cntigo las 24horas",
  "Era decirte que, aunque pase el tiempo, tengamos mucha más confianza, y yo me relaje en algunos momentos. Nunca voy a dejar de mirar por ti y a estar pendiente a ti. Que eres la alegría de mi vida.",
  "Gregorio",
  "Que bonito despertar así",
  "Yacoyaiyo :)",
  "Cómo siento esto tan bonito. Cómo puedo estar todo el día contigo y aún así querer más y más.",
  "Tienes que saber que estoy loco de amor por ti. Que veo tu cara y directamente me sale una sonrisa, y ese sentimiento es por que empiezo a conocerte bien. Y sé qué hay dentro de ti.",
  "Pero es que para colmo veo lo de fuera.. y me entra una cosita de nervios que pá qué. ",
  "Me vuelves loco. De cabeza a pies. No olvides eso. Y no dudes nunca.",
  "Bendito 25 d abril cuando me diste ese beso y ya no quiero dejar de darte nunca",
  "Tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq tq",
  "Ayer se me rompió el alma al verte llorar. Y me di cuenta de qué significas para mi (Máster Barcelona)",
  "Te has convertido en alguien muy muy importante en mi vida.",
  "Siento por ti una cosa muy bonita, que algunas veces me cuesta hasta explicar.",
  "Espero que sepas que estoy aquí para lo bueno buenísimo y para lo que no es tan bueno",
  "Sé que no me vas a leer mañana al despertar. Pero me gusta mucho meterme en la camita y mandarte mi mensajillo, y hoy no quería ser menos. (Tu móvil roto)",
  "Me encanta estar contigo, siempre",
  "Pues mientras hacía todo eso. De vez en cuando me acordaba y pensaba: ay mi niña que hoy la abrazo!",
  "Amanecer de nuevo, Y encontrar tu cara, Eso es un milagro sobre la almohada.",
  "mirarnos en el espejo (carita del revés)",
  "tu regalo es haberme elegido como compañero de viaje en esta loca aventura llamada vida 😂",
  "mi niña",
  "La casa de Cesar Manrique",
  "Los dos en la playa",
  "Cogerte en brazos dentro del mar",
  "Pasear contigo de la mano",
  "Tu cabeza en mi hombro",
  "tenerte al lado y tener que escribirte para decirte lo mucho que te quiero",
  "Que bonita eres Belén Alcoba Otón",
  "Me encanta estar cntigo, desde qnos abrazamos nada más vernos hasta qnos despedimos cn otro abrazo",
  "Me encanta que seas así conmigo",
  "Me gustas hasta más",
  "Y hace cinco meses llegaste tú",
  "Y ahora entiendo realmente que es ser feliz",
  "Y que cosa más bonita es lo que siento cuando te miro",
  "Que bonito enamorarte de alguien y que esa persona también se enamore de tí",
  "Que bonito lo que nos está pasando",
  "#lasuertedemivida",
  "Hoy te veo ❤️",
  "Mi plan eres tú",
  "Hasta las trancas",
  "Y el saber que eres feliz me hace feliz a mi. Suena típico pero es así.",
  "Si tu supieras lo que me entra x el cuerpo de saberlo",
  "Sé que nosotros nos queremos, y nos cuidamos.",
  "Bonita eres joia!",
  "Buenos días mi amor!",
  "Te lo dije desde un principio, ten seguro que voy a esforzarme en que seas así de feliz siempre. Xq no querría otra cosa para alguien a la que quiero tanto.",
  "Solo me faltas tú",
  "I love you, en inglés",
  "Ti amo, en italiano",
  "Y lo mucho que te quiero te lo digo en castellano",
  "Quiero vivir contigo",
  "Yo, a ti, no sabes cuánto",
  "Comerte a besos",
  "Esta es la 365, la última que escribo. Te saldrá en cualquier momento. Todo lo que pone aquí lo sigo sintiendo como el primer día. Feliz Aniversario. Cada día más ❤️"
];