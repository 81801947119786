import React, { Component } from 'react';

import { shuffle } from './app-utils';
import { data } from './data';
import './App.css';

export default class App extends Component {

    state = {
        dataIndexPosition: 0,
        dataShuffled: []
    }

    componentDidMount(){
        this.setState({
            dataShuffled: shuffle(data)
        });
    }

    incrementIndex = _ => {
        const { dataIndexPosition } = this.state;
        let newDataIndexPosition;

        if(dataIndexPosition < data.length - 1){
            newDataIndexPosition = dataIndexPosition + 1;
        } else if(dataIndexPosition === data.length - 1){
            newDataIndexPosition = 0;
        }

        this.setState({
            dataIndexPosition: newDataIndexPosition
        });
    }

    render() {
        const { dataShuffled, dataIndexPosition } = this.state;
        return (
            <div id="container" onClick={this.incrementIndex}>
                <p>
                   {dataShuffled[dataIndexPosition]}
                </p>
            </div>
        );
    }
}